/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AcceptTermsData,
  CreateChatData,
  CreateChatPayload,
  CreateStripeCustomerPortalSessionData,
  GetChatData,
  GetNewsData,
  GetNewsPayload,
  HealthData,
  ListChatsData,
  RefreshStripeSubscriptionData,
  TranslateData,
  TranslateParams,
  UserInfoData,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Backend<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Health contract
   * @name Health
   * @request GET:/backend/health
   */
  health = (params: RequestParams = {}) =>
    this.request<
      HealthData,
      {
        message: string;
      }
    >({
      path: `/backend/health`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Chat contract
   * @name ListChats
   * @request GET:/backend/chats
   */
  listChats = (params: RequestParams = {}) =>
    this.request<ListChatsData, any>({
      path: `/backend/chats`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Chat contract
   * @name CreateChat
   * @request POST:/backend/chats
   */
  createChat = (data: CreateChatPayload, params: RequestParams = {}) =>
    this.request<CreateChatData, any>({
      path: `/backend/chats`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Chat contract
   * @name GetChat
   * @request GET:/backend/chats/{id}
   */
  getChat = (id: string, params: RequestParams = {}) =>
    this.request<GetChatData, any>({
      path: `/backend/chats/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Chat contract
   * @name Translate
   * @request GET:/backend/translate
   */
  translate = (query: TranslateParams, params: RequestParams = {}) =>
    this.request<TranslateData, any>({
      path: `/backend/translate`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Chat contract
   * @name GetNews
   * @request POST:/backend/news
   */
  getNews = (data: GetNewsPayload, params: RequestParams = {}) =>
    this.request<GetNewsData, any>({
      path: `/backend/news`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User contract
   * @name UserInfo
   * @request GET:/backend/userinfo
   */
  userInfo = (params: RequestParams = {}) =>
    this.request<UserInfoData, any>({
      path: `/backend/userinfo`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User contract
   * @name AcceptTerms
   * @request POST:/backend/accept-terms
   */
  acceptTerms = (params: RequestParams = {}) =>
    this.request<AcceptTermsData, any>({
      path: `/backend/accept-terms`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User contract
   * @name RefreshStripeSubscription
   * @request GET:/backend/stripe/refresh-subscription
   */
  refreshStripeSubscription = (params: RequestParams = {}) =>
    this.request<RefreshStripeSubscriptionData, any>({
      path: `/backend/stripe/refresh-subscription`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User contract
   * @name CreateStripeCustomerPortalSession
   * @request POST:/backend/stripe/customer-portal-session
   */
  createStripeCustomerPortalSession = (params: RequestParams = {}) =>
    this.request<CreateStripeCustomerPortalSessionData, any>({
      path: `/backend/stripe/customer-portal-session`,
      method: "POST",
      format: "json",
      ...params,
    });
}
