import { Backend } from "~/generated/Backend";

export const useApi = () => {
  const runtimeConfig = useRuntimeConfig();

  const cookie = process.server ? useRequestHeaders()?.cookie?.split("auth_session=")[1] : useCookie("auth_session");

  const api = new Backend({
    baseUrl: runtimeConfig.public.apiBase,
    baseApiParams: {
      headers: {
        cookie: `auth_session=${cookie}`,
      },
    },
  });
  return api;
};
